
<template>
  <div class="cooperation-container">
    <div class="main-img-wrapper">
      <div class="main-img-caption">
        <h1 class="main-title">业务范围</h1>
      </div>
      <div id="container"
           ref="myEchart"
           class="chart"></div>
    </div>
    <section class="partner stripe">
      <b-container>
        <b-row class="flex-center hm-title">
          <b-col cols="3">
            <hr />
          </b-col>
          <b-col cols="6"
                 class="text-center">
            <h3>合作伙伴</h3>
          </b-col>
          <b-col cols="3">
            <hr />
          </b-col>
        </b-row>
        <div class="logo-wall text-center">
          <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/hemiao/2019-5/26678a61-7750-4e2a-8117-6a1d48634109.jpg"
               class="img-fluid"
               alt="">
        </div>
        <!-- <b-row class="partner-list main-list">
          <b-col class="list-item-wrapper" cols="6" md="4">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/21740835-0ee1-4005-8da7-d04b505c7f6f.png" alt="门店标志" class="img-fluid" />
              <div class="store-intro flex flex-column flex-center">
                <h3>湖北· 可恩宝贝</h3>
                <p>
                  湖北知名母婴用品销售企业， 现拥有12家门店。 主营婴儿食品、
                  服饰、 婴儿车床、 婴儿游泳、 纪念品等二十余系列、
                  万余个品种
                </p>
              </div>
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="6" md="4">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/e150efcd-69df-445b-9b21-86ac2c1a2e1c.png" alt="门店标志" class="img-fluid" />
              <div class="store-intro flex flex-column flex-center">
                <h3>江西· 爱婴宝</h3>
                <p>
                  在九江市已有6家直营门店， 涵盖0-6
                  岁宝宝及准妈妈吃穿用育住行的专业母婴连锁公司
                </p>
              </div>
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="6" md="4">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/ef3f8814-0e1d-4be8-b43e-2df7fb9d81f9.png" alt="门店标志" class="img-fluid" />

              <div class="store-intro flex flex-column flex-center">
                <h3>重庆· 登康-好儿尚</h3>
                <p>
                  公司旗下拥有20家直营店， 涵盖孕婴行业的吃、 穿、 住、 行、
                  育、 乐各类别的产品
                </p>
              </div>
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="6" md="4">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/1d730eed-e450-4c7e-b236-029d67c69e97.png" alt="门店标志" class="img-fluid" />
              <div class="store-intro flex flex-column flex-center">
                <h3>内蒙古· 世纪瑶篮</h3>
                <p>
                  致力于打造成呼和浩特最大规模婴幼儿用品连锁店，
                  为宝宝和妈妈提供母婴服务、 玩教一体、 商品零售一站式服务！
                </p>
              </div>
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="6" md="4">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/e458013c-1126-465f-bc5b-d072948fa8a1.png" alt="门店标志" class="img-fluid" />

              <div class="store-intro flex flex-column flex-center">
                <h3>云南· 登康E购</h3>
                <p>
                  属于登康集团的母婴品牌，
                  登康集团从事母婴行业时间已超过20年，
                  被行业列为全国10大母婴连锁之一
                </p>
              </div>
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="6" md="4">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/a3beecdd-dd19-4b47-ac67-4112c5b76a87.png" alt="门店标志" class="img-fluid" />

              <div class="store-intro flex flex-column flex-center">
                <h3>黑龙江· 爱特孕婴童</h3>
                <p>
                  成立于2005年， 致力于为孕产期的妈妈及宝宝提供衣、 食、
                  住、 行所需的一站式商品及服务
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="partner-list other-list">
          <b-col class="list-item-wrapper" cols="4" md="3">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/93144653-51a3-4162-b5b4-c270a7ab0c4d.png" alt="门店标志" class="img-fluid" />
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="4" md="3">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/9621b285-a8e3-45fa-af05-b018c1c067ef.png" alt="门店标志" class="img-fluid" />
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="4" md="3">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/9a85511d-b0a3-4861-b03e-53ff935f1589.png" alt="门店标志" class="img-fluid" />
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="4" md="3">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/44c5ed08-e055-47b8-a8ef-a468fdb1471b.png" alt="门店标志" class="img-fluid" />
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="4" md="3">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/8a22a692-17cf-41e6-bb32-6e392bf0cad5.png" alt="门店标志" class="img-fluid" />
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="4" md="3">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/f7126286-0f59-4e56-bac0-720db10a992d.png" alt="门店标志" class="img-fluid" />
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="4" md="3">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/dd420372-5fbd-4999-a1f0-3a3cc90be7a8.png" alt="门店标志" class="img-fluid" />
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="4" md="3">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/ba787efb-6133-48f7-8461-ec079b6836cb.png" alt="门店标志" class="img-fluid" />
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="4" md="3">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/e944062a-a970-401d-abb8-4e35ce3bcadc.png" alt="门店标志" class="img-fluid" />
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="4" md="3">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/94b12c8e-ce6e-4f24-bfac-d15502cef2ea.png" alt="门店标志" class="img-fluid" />
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="4" md="3">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/02a1b7fb-67b5-4126-b802-6fc13d30183d.png" alt="门店标志" class="img-fluid" />
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="4" md="3">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/b1432207-5210-493c-9ada-06c3bd533455.png" alt="门店标志" class="img-fluid" />
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="4" md="3">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/03686003-c764-4585-a70c-49138e72e0e9.png" alt="门店标志" class="img-fluid" />
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="4" md="3">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/d996232a-a043-4875-a13c-48a0ff5061de.png" alt="门店标志" class="img-fluid" />
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="4" md="3">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/3a7a1bd9-0fdd-46a5-909b-1488e6373557.png" alt="门店标志" class="img-fluid" />
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="4" md="3">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/e672c358-0d15-424e-ba29-726d00db56c7.png" alt="门店标志" class="img-fluid" />
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="4" md="3">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/0d43d033-3af3-49ad-adb3-2242c1b9448c.png" alt="门店标志" class="img-fluid" />
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="4" md="3">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/af364557-1353-448c-991c-6c5518d9b098.png" alt="门店标志" class="img-fluid" />
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="4" md="3">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/97b1eb5c-2c28-4140-9c58-7091f3e50028.png" alt="门店标志" class="img-fluid" />
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="4" md="3">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/270472fc-0424-4aee-a3b5-1a8a8a7c04e6.png" alt="门店标志" class="img-fluid" />
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="4" md="3">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/255b450a-349a-4e7c-aea1-3fa77ddfa4ab.png" alt="门店标志" class="img-fluid" />
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="4" md="3">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/eaeeb10e-4a67-45c2-805b-6de23be22939.png" alt="门店标志" class="img-fluid" />
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="4" md="3">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/9e5021b7-c729-410f-81b6-6db2b97a475b.png" alt="门店标志" class="img-fluid" />
            </div>
          </b-col>
          <b-col class="list-item-wrapper" cols="4" md="3">
            <div class="img-wrapper list-item">
              <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/33a80d4f-2080-4731-b822-ed5d87a9e7fe.png" alt="门店标志" class="img-fluid" />
            </div>
          </b-col>
        </b-row> -->
      </b-container>
    </section>
  </div>
</template>
<script>
import echarts from 'echarts'
import '../../node_modules/echarts/map/js/china.js'
export default {
  data () {
    return {}
  },
  mounted () {
    const myChart = echarts.init(this.$refs.myEchart)
    const geoCoordMap = {
      湖北: [114.3896, 30.6628],
      安徽: [117.2922, 31.8673],
      湖南: [112.9900, 28.1183],
      江西: [115.9164, 28.6804],
      黑龙江: [127.9688, 45.3685],
      内蒙古: [111.4124, 40.4901],
      四川: [104.0823, 30.6567],
      重庆: [106.5571, 29.5710],
      山西: [112.5690, 37.8797],
      广西: [108.3340, 22.8212],
      广东: [113.2727, 23.1379],
      福建: [119.3029, 26.0803],
      辽宁: [123.4695, 41.6833],
      云南: [102.8396, 24.8859]
    }
    const WHData = [
      // 数据中name的城市名称必须与geoCoordMap中城市名称一致, 不然关联不上，湖北到各地区的线路，值越大点越大
      [{
        name: '湖北' // 设置湖北到湖北用于在地图显示湖北
      }, {
        name: '湖北',
        value: 80
      }],
      [{
        name: '湖北'
      }, {
        name: '安徽',
        value: 50
      }],
      [{
        name: '湖北'
      }, {
        name: '内蒙古',
        value: 50
      }],
      [{
        name: '湖北'
      }, {
        name: '黑龙江',
        value: 50
      }],
      [{
        name: '湖北'
      }, {
        name: '江西',
        value: 50
      }],
      [{
        name: '湖北'
      }, {
        name: '四川',
        value: 50
      }],
      [{
        name: '湖北'
      }, {
        name: '重庆',
        value: 50
      }],
      [{
        name: '湖北'
      }, {
        name: '山西',
        value: 50
      }],
      [{
        name: '湖北'
      }, {
        name: '湖南',
        value: 50
      }],
      [{
        name: '湖北'
      }, {
        name: '广东',
        value: 50
      }],
      [{
        name: '湖北'
      }, {
        name: '广西',
        value: 50
      }],
      [{
        name: '湖北'
      }, {
        name: '云南',
        value: 50
      }],
      [{
        name: '湖北'
      }, {
        name: '福建',
        value: 50
      }],
      [{
        name: '湖北'
      }, {
        name: '辽宁',
        value: 50
      }]
    ]
    const planePath = 'arrow' // 箭头的svg
    // push进去线路开始-结束地点-经纬度
    const convertData = function (data) {
      const res = []
      for (let i = 0; i < data.length; i++) {
        const dataItem = data[i]
        const fromCoord = geoCoordMap[dataItem[0].name]
        const toCoord = geoCoordMap[dataItem[1].name]
        if (fromCoord && toCoord) {
          res.push([{
            coord: fromCoord
          },
          {
            coord: toCoord
          }
          ])
        }
      }
      return res
    }
    const color = ['#fff', '#ddd', '#fff'] // 圆圈和字的颜色，线的颜色，箭头颜色
    // 数据
    const series = []
      // 遍历由湖北到其他城市的线路
      ;
    [
      ['湖北', WHData]
    ].forEach(function (item, i) {
      // 配置
      series.push({
        // 系列名称，用于tooltip的显示
        name: item[0],
        type: 'lines',
        zlevel: 1, // 用于 Canvas 分层，不同zlevel值的图形会放置在不同的 Canvas 中
        // effect出发到目的地 的白色尾巴线条
        // 线特效的配置
        effect: {
          show: true,
          period: 6, // 特效动画的时间，单位为 s
          trailLength: 0, // 特效尾迹的长度。取从 0 到 1 的值，数值越大尾迹越长
          color: '#fff', // 移动箭头颜色
          symbol: planePath,
          symbolSize: 6 // 特效标记的大小
        },
        // lineStyle出发到目的地 的线条颜色
        lineStyle: {
          normal: {
            color: color[i],
            width: 0,
            curveness: 0.2 // 幅度
          }
        },
        data: convertData(item[1]) // 开始到结束数据
      }, {
        // 出发地信息
        name: item[0],
        type: 'lines',
        zlevel: 2,
        effect: {
          show: true,
          period: 6,
          trailLength: 0,
          symbol: planePath,
          symbolSize: 6
        },
        lineStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(
              0,
              0,
              0,
              1,
              [{
                offset: 0,
                color: '#fff' // 出发
              },
              {
                offset: 1,
                color: '#fff ' // 结束 颜色
              }
              ],
              false
            ),
            width: 1.5,
            opacity: 0.4,
            curveness: 0.2
          }
        },
        data: convertData(item[1])
      }, {
        // 目的地信息
        name: item[0],
        type: 'effectScatter',
        coordinateSystem: 'geo',
        zlevel: 2,
        rippleEffect: {
          brushType: 'stroke'
        },
        label: {
          normal: {
            show: true,
            position: 'top',
            formatter: '{b}'
          }
        },
        symbolSize: function (val) {
          return val[2] / 8
        },
        itemStyle: {
          normal: {
            color: color[i]
          }
        },
        data: item[1].map(function (dataItem) {
          return {
            name: dataItem[1].name,
            value: geoCoordMap[dataItem[1].name].concat([dataItem[1].value])
          }
        })
      })
    })

    // 指定相关的配置项和数据
    const option = {
      geo: {
        map: 'china',
        roam: false, // 是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。设置成 true 为都开启
        aspectScale: 0.75,
        zoom: 1.2,
        label: {
          normal: {
            show: false,
            textStyle: {
              color: '#00a0c9'
            }
          },
          emphasis: {
            // 对应的鼠标悬浮效果
            show: false,
            textStyle: {
              color: '#00a0c9'
            }
          }
        },
        itemStyle: {
          normal: {
            areaColor: '#30ADFF',
            borderColor: '#fff'
          },
          emphasis: {
            borderWidth: 0,
            borderColor: '#0062cc',
            areaColor: '#0062cc',
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      },
      series: series
    }
    // 使用制定的配置项和数据显示图表
    myChart.setOption(option)
    window.onresize = myChart.resize
  }
}
</script>

<style scoped lang = "less" >
@distance: 40px;
.partner {
  padding: @distance 0;
  .list-item-wrapper {
    margin-bottom: 40px;
  }
  .img-wrapper {
    padding: @distance 10px;
    text-align: center;
    cursor: pointer;
    height: 100%;
    background: #fff;
    transition: all 0.2 s linear;
    box-shadow: 0px 3px 3px rgba(50, 50, 50, 0.1);
    border-radius: 5px;
  }
  .other-list {
    .img-wrapper:hover {
      box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    }
  }
  .title {
    margin-top: 20px;
  }
  .hm-title {
    margin-bottom: 40px;
  }
}
.cooperation-container {
  .main-img-wrapper {
    margin-top: 53px;
    padding-top: 40px;
    .main-img-caption {
      background: transparent;
    }
  }
  .main-title {
    color: #000;

    margin-top: 120px;
  }

  .main-list {
    .list-item {
      position: relative;
    }

    .img-wrapper:hover {
      .store-intro {
        opacity: 1;

        transition: opacity 0.2s linear;

        padding: 10px;

        h3 {
          color: #fff;
        }
        p {
          color: #fff;
          text-indent: 2em;
          text-align: justify;
        }
      }
    }
    .store-intro {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      border-radius: 5px;
    }
  }
}

.chart {
  height: 600px;
  width: 100%;
  min-width: 300px;
}

@media (max-width: 767px) {
  .cooperation-container {
    .main-title {
      color: #333;
      font-size: 20px;
      margin-top: 32px;
    }
  }

  .chart {
    height: 300px;
  }
  .partner {
    .img-wrapper {
      padding: 10px;
    }
    .main-list {
      .img-wrapper:hover {
        .store-intro {
          h3 {
            font-size: 16px;
          }
          p {
            font-size: 10px;
          }
        }
      }
    }
  }
}
</style>
